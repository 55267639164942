<template>
  <div>
    <el-row justify="space-between" align="middle">
      <el-col :span="6"><h3 class="mb-0">Connect a doctor with</h3></el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-input v-model="search" placeholder="Please input"/>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="getDcotors">Search</el-button>
      </el-col>
    </el-row>
    <br>
    <el-table :data="tableData" border style="width: 100%" v-if="tableData.length">
      <el-table-column prop="name" label="Check schedule">
        <template #default="scope">
          <el-button type="primary" circle><el-icon><List /></el-icon></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name" width="300">
        <template #default="scope">
          <UserCard :name="scope.row.details.name" :phone_number="scope.row.details.phone_number" :image="scope.row.details.image">
            <p>Exp. {{ scope.row.details.year_of_exp}}</p>
          </UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="speciality" label="Card intro">
        <template #default="scope">
          {{ scope.row.details.card_intro }}
        </template>
      </el-table-column>
      <el-table-column prop="degrees" label="Degree">
        <template #default="scope">
          {{ scope.row.details.degrees }}
        </template>
      </el-table-column>
      <el-table-column prop="specialities" label="Specialities">
        <template #default="scope">
          {{ scope.row.details.specialities }}
        </template>
      </el-table-column>
      <el-table-column prop="fee" label="Fee">
        <template #default="scope">
          <p>Virtual Fee: <b>{{ scope.row.details.call_fee }}</b></p>
          <p>Visit Fee: <b>{{ scope.row.details.visit_fee }}</b> </p>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import axios from 'axios';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'Search',
  components: {
    UserCard,
  },
  data() {
    return {
      tableData: [
        {
          name: '',
        },
        {
          speciality: '',
        },
        {
          designation: '',
        },
        {
          created_at: '',
        },
      ],
      search: '',
    };
  },
  created() {
    // this.getDcotors();
  },
  // computed: {
  //   filteredList() {
  //     return this.tableData.filter(
  //         (data) =>
  //             !this.search ||
  //             data.name.toLowerCase().includes(this.search.toLowerCase())
  //     )
  //   }
  // },
  methods: {
    getDcotors() {
      axios.get(`/api/v1/search/information?search_data_type=name&searched_text=${this.search}&type=user`)
        .then((response) => {
          this.tableData = response.data.data;
        });
    },
  },
  title: 'Doctors Search',
};
</script>

<style scoped>
p{
  margin: 0;
  padding: 0;
}
</style>
